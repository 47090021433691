<template>
	<!-- 商品资料 -->
	<div class="commodityData">
		<div class="search">
			<div class="search-l">
				<div class="l-item">
					<div class="item-label">关键字:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入关键字搜索" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">分类:</div>
					<div class="item-input">
						<el-select v-model="searchForm.category_id" placeholder="请选择分类" style="width: 100%;">
							<el-option v-for="item in classifyList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择分类" style="width: 100%;">
							<el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click="page = 1, loading = true, getList()">搜索</el-button>
					<el-button @click="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus"
					@click="dialogVisible = true, dialogType = 1, form = { arrived_type: 1 }">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="商品图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="商品名称" prop="goods_name" align="center" width="150"></el-table-column>
				<el-table-column label="商品简介" prop="goods_intro" align="center" width="170"></el-table-column>
				<el-table-column label="预订价" prop="price" sortable align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.price" placeholder="请输入预订价"
							@blur="inputBlur($event, scope.row.id, scope.row.price, 'price')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="最低折扣价" prop="min_price" sortable align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.min_price" placeholder="请输入最低折扣价"
							@blur="inputBlur($event, scope.row.id, scope.row.min_price, 'min_price')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="指导价" prop="guide_price" sortable align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.guide_price" placeholder="请输入指导价"
							@blur="inputBlur($event, scope.row.id, scope.row.guide_price, 'guide_price')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="时价" prop="actual_price" sortable align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.actual_price" placeholder="请输入时价"
							@blur="inputBlur($event, scope.row.id, scope.row.actual_price, 'actual_price')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="成本价" prop="cost" sortable align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.cost" placeholder="请输入成本价"
							@blur="inputBlur($event, scope.row.id, scope.row.cost, 'cost')"
							:disabled="freestyle_cost == 2"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="库存" prop="stock" sortable align="center" width="100">
					<template slot-scope="scope">
						<el-input v-model="scope.row.stock" placeholder="请输入库存" oninput="value=value.replace(/[^0-9]/g,'')"
							@blur="inputBlur($event, scope.row.id, scope.row.stock, 'stock')"
							:disabled="freestyle_stock == 2"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="预警库存" prop="warning_stock" sortable align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.warning_stock" placeholder="请输入预警库存"
							oninput="value=value.replace(/[^0-9]/g,'')"
							@blur="inputBlur($event, scope.row.id, scope.row.warning_stock, 'warning_stock')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="最小起购" align="center" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row.limit_min }}件</span>
					</template>
				</el-table-column>
				<el-table-column label="单次限购" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.quota == 0">不限购</span>
						<span v-else>{{ scope.row.quota }}件</span>
					</template>
				</el-table-column>
				<el-table-column label="到货类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.arrived_type == 1">当日达</span>
						<span v-if="scope.row.arrived_type == 2">次日达</span>
						<span v-if="scope.row.arrived_type == 3">预售<br> ({{ scope.row.arrived_date }}到)</span>
					</template>
				</el-table-column>
				<el-table-column label="截单时间" align="center" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row.cut_time ? scope.row.cut_time : "全局设置" }}</span>
					</template>
				</el-table-column>
				<el-table-column label="分类" prop="category_name" align="center"></el-table-column>
				<el-table-column label="秒杀商品" align="center" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row.is_seckill == 0 ? '否' : '是' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="清仓商品" align="center" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row.is_clear == 0 ? '否' : '是' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="自定义属性" prop="attribute" align="center"></el-table-column>
				<el-table-column label="排序" prop="sort" sortable align="center">
					<template slot-scope="scope">
						<el-input v-model="scope.row.sort" placeholder="请输入商品排序"
							@blur="inputBlur($event, scope.row.id, scope.row.sort, 'sort')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160" fixed="right">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click="removeFn(scope.row)" v-if="scope.row.status == 2">
							删除</el-button>
						<el-button type="info" size="mini" @click="xiajiaFn(scope.row)"
							v-if="scope.row.status == 1">下架</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" width="50%"
			:close-on-click-modal="false" v-el-drag-dialog>
			<div style="height:500px;" class="scrollbar">
				<el-scrollbar style="height:100%">
					<el-form ref="form" :model="form" label-width="100px" style="padding-right: 30px;">
						<el-form-item label="分类">
							<el-select v-model="form.category_id" placeholder="请选择分类" style="width: 100%;"
								@change="form.category_id = $event">
								<el-option v-for="item in classifyList" :key="item.id" :label="item.title" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="商品名称" required>
							<el-input v-model="form.goods_name" placeholder="请输入商品名称"></el-input>
						</el-form-item>
						<el-form-item label="商品主图" required>
							<el-upload class="avatar-uploader" :action="serverUrl" :headers="uploadHeaders"
								:show-file-list="false" :on-success="handleAvatarSuccess">
								<img v-if="form.goods_pic" :src="form.goods_pic" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="商品简介" required>
							<el-input v-model="form.goods_intro" placeholder="请输入商品简介"></el-input>
						</el-form-item>
						<el-form-item label="预估成本价">
							<el-input v-model="form.cost" placeholder="请输入商品预估成本价"
								:disabled="freestyle_cost == 2"></el-input>
						</el-form-item>
						<el-form-item label="预订价" required>
							<el-input v-model="form.price" placeholder="请输入商品预订价"></el-input>
						</el-form-item>
						<el-form-item label="零售指导价" required>
							<el-input v-model="form.guide_price" placeholder="请输入商品零售指导价"></el-input>
						</el-form-item>
						<el-form-item label="最低折扣价">
							<el-input v-model="form.min_price" placeholder="请输入商品最低折扣价"></el-input>
						</el-form-item>
						<el-form-item label="时价">
							<el-input v-model="form.actual_price" placeholder="后结算时请输入商品时价"></el-input>
						</el-form-item>
						<el-form-item label="库存" required>
							<el-input v-model="form.stock" placeholder="请输入商品库存" oninput="value=value.replace(/[^0-9]/g,'')"
								:disabled="freestyle_stock == 2"></el-input>
						</el-form-item>
						<el-form-item label="预警库存">
							<el-input v-model="form.warning_stock" placeholder="请输入商品预警库存"
								oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
						</el-form-item>
						<el-form-item label="最小起购" required>
							<el-radio-group v-model="isLimitMin">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="1">是</el-radio>
							</el-radio-group>
							<el-input v-if="isLimitMin == 1" v-model="form.limit_min" placeholder="请输入商品最小起购数量"></el-input>
						</el-form-item>
						<el-form-item label="单次限购" required>
							<el-radio-group v-model="isQuota">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="1">是</el-radio>
							</el-radio-group>
							<el-input v-if="isQuota == 1" v-model="form.quota" placeholder="请输入商品最小起购数量"></el-input>
						</el-form-item>
						<el-form-item label="到货类型" required>
							<el-radio-group v-model="form.arrived_type">
								<el-radio :label="1">当日达</el-radio>
								<el-radio :label="2">次日达</el-radio>
								<el-radio :label="3">预售</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="到货日期" required v-if="form.arrived_type == 3">
							<el-date-picker v-model="form.arrived_date" type="date" placeholder="选择到货日期"
								value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="截单时间">
							<el-time-picker v-model="form.cut_time" placeholder="选择截单时间" value-format="HH:mm:ss"
								:editable="false" style="width: 100%;" :clearable="true">
							</el-time-picker>
						</el-form-item>

						<el-form-item label="标记秒杀商品">
							<el-select v-model="form.is_seckill" placeholder="请选择是否标记秒杀商品" style="width: 100%;"
								@change="form.is_seckill = $event">
								<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="标记清仓商品">
							<el-select v-model="form.is_clear" placeholder="请选择是否标记清仓商品" style="width: 100%;"
								@change="form.is_clear = $event">
								<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="自定义属性">
							<el-input v-model="form.attribute" placeholder="请输入自定义属性"></el-input>
						</el-form-item>
						<el-form-item label="是否置顶">
							<el-radio-group v-model="form.is_top">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="1">是</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="排序">
							<el-input v-model="form.sort" placeholder="数字越大越靠前"></el-input>
						</el-form-item>
						<el-form-item label="商品详情">
							<quill-editor v-model="form.goods_desc" ref="myQuillEditor" :options="editorOption">
								<div id="toolbar" slot="toolbar">
									<button class="ql-bold" title="加粗">Bold</button>
									<button class="ql-italic" title="斜体">Italic</button>
									<button class="ql-underline" title="下划线">underline</button>
									<button class="ql-strike" title="删除线">strike</button>
									<button class="ql-blockquote" title="引用"></button>
									<button class="ql-code-block" title="代码"></button>
									<button class="ql-header" value="1" title="标题1"></button>
									<button class="ql-header" value="2" title="标题2"></button>
									<button class="ql-list" value="ordered" title="有序列表"></button>
									<button class="ql-list" value="bullet" title="无序列表"></button>
									<select class="ql-header" title="段落格式">
										<option selected>段落</option>
										<option value="1">标题1</option>
										<option value="2">标题2</option>
										<option value="3">标题3</option>
										<option value="4">标题4</option>
										<option value="5">标题5</option>
										<option value="6">标题6</option>
									</select>
									<select class="ql-size" title="字体大小">
										<option value="10px">10px</option>
										<option value="12px">12px</option>
										<option value="14px">14px</option>
										<option value="16px" selected>16px</option>
										<option value="18px">18px</option>
										<option value="20px">20px</option>
										<option value="30px">30px</option>
									</select>
									<select class="ql-font" title="字体">
										<option value="SimSun">宋体</option>
										<option value="SimHei">黑体</option>
										<option value="Microsoft-YaHei">微软雅黑</option>
										<option value="KaiTi">楷体</option>
										<option value="FangSong">仿宋</option>
										<option value="Arial">Arial</option>
									</select>
									<select class="ql-color" value="color" title="字体颜色"></select>
									<select class="ql-background" value="background" title="背景颜色"></select>
									<select class="ql-align" value="align" title="对齐"></select>
									<button class="ql-clean" title="清除字体样式"></button>
									<button class="ql-image" title="图片"></button>
									<button class="ql-video" title="视频"></button>
								</div>
							</quill-editor>
							<el-upload class="avatar-uploader-editor" multiple :headers="uploadHeaders" :action="serverUrl"
								:show-file-list="false" :on-success="uploadSuccess" :on-error="uploadError">
							</el-upload>
							<el-upload class="avatar-uploader-editor-video" :headers="uploadHeaders" :action="serverUrl"
								:data="{
									type: 3
								}" :show-file-list="false" :on-success="uploadSuccessVideo" :on-error="uploadError">
							</el-upload>
						</el-form-item>
					</el-form>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="saveGoods">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	quillEditor
} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import CryptoJS from 'crypto-js'
var timestamp = new Date().getTime()
export default {
	components: {
		quillEditor
	},
	data() {
		return {
			searchForm: {},
			tableData: [],
			loading: true,
			page: 1,
			pageNum: 10,
			total: 0,
			// 新增、编辑弹窗
			dialogType: 1, //1新增2编辑
			dialogVisible: false,
			form: {
				arrived_type: 1,
				goods_desc: ''
			},
			isLimitMin: 0, //最小起购
			isQuota: 0, //单次限购
			classifyList: [], //分类
			statusList: [{
				id: 0,
				name: '全部'
			},
			{
				id: 1,
				name: '正常'
			},
			{
				id: 2,
				name: '下架'
			},
			{
				id: 3,
				name: '违规'
			}
			], //状态
			typeList: [{
				id: 0,
				name: '否'
			},
			{
				id: 1,
				name: '是'
			}
			],
			uploadHeaders: {
				token: window.sessionStorage.getItem('token'),
				sign: CryptoJS.MD5(sessionStorage.getItem('token') + 'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp)
					.toString(),
				timestamp: timestamp
			},
			editorOption: {
				placeholder: "请输入",
				theme: "snow",
				modules: {
					toolbar: {
						container: '#toolbar',
						handlers: {
							'image': function (value) {
								if (value) {
									// 触发input框选择图片文件                    
									document.querySelector('.avatar-uploader-editor input').click()
								} else {
									this.quill.format('image', false);
								}
							},
							'video': function (value) {
								if (value) {
									// 触发input框选择视频文件                    
									document.querySelector('.avatar-uploader-editor-video input').click()
								} else {
									this.quill.format('video', false);
								}
							}
						}
					}
				}
			},
			serverUrl: 'erp/v1/common/upload',
			freestyle_stock: 1,
			freestyle_cost: 1
		}
	},
	mounted() {
		this.getList()
		this.getClassifyList()
		this.getSupplierInfo()
	},
	methods: {
		// 供应商信息
		getSupplierInfo() {
			this.$http.post(`/erp/v1/home/get_info`).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					this.freestyle_cost = data.freestyle_cost
					this.freestyle_stock = data.freestyle_stock
				} else {
					this.$message.error(msg);
				}
			});
		},
		// 富文本图片上传成功
		uploadSuccess(res, file) {
			let quill = this.$refs.myQuillEditor.quill
			// 如果上传成功        
			if (res.code == 200) {
				// 获取光标所在位置          
				let length = quill.getSelection().index;
				// 插入图片res.url为服务器返回的图片地址
				quill.insertEmbed(length, 'image', res.data.path)
				// 调整光标到最后         
				quill.setSelection(length + 1)
			} else {
				this.$message.error('图片插入失败')
			}
		},
		//上传视频
		uploadSuccessVideo(res, file) {
			let quill = this.$refs.myQuillEditor.quill
			// 如果上传成功        
			if (res.code == 200) {
				// 获取光标所在位置          
				let length = quill.getSelection().index;
				// 插入图片res.url为服务器返回的地址  
				quill.insertEmbed(length, 'video', res.data.path)

				// 调整光标到最后            
				quill.setSelection(length + 1)
			} else {
				this.$message.error('视频插入失败')
			}
		},
		// 富文本图片/视频/音频上传失败   
		uploadError() {
			this.$message.error('插入失败')
		},
		getList() {
			this.$http.post(`/erp/v1/goods/lst`, {
				page: this.page,
				pageNum: this.pageNum,
				...this.searchForm
			}).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					this.tableData = data.rows
					this.tableData.forEach(item => {
						item.price = Number(item.price)
						item.min_price = Number(item.min_price)
						item.guide_price = Number(item.guide_price)
						item.actual_price = Number(item.actual_price)
						item.cost = Number(item.cost)
						item.stock = Number(item.stock)
						item.warning_stock = Number(item.warning_stock)
					});
					this.total = data.total
				} else {
					this.$message.error(msg);
				}
			});
		},
		// 分类
		getClassifyList() {
			this.$http.post(`/erp/v1/goods_category/lst`, {
				page: this.page,
				pageNum: this.pageNum,
			}).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					this.classifyList = data.rows
				} else {
					this.$message.error(msg);
				}
			});
		},
		// 编辑弹窗
		openDialog(item) {
			this.form = {
				...item
			}
			this.form.is_top > 0 ? this.form.is_top = 1 : this.form.is_top = 0
			this.form.limit_min <= 1 ? this.isLimitMin = 0 : this.isLimitMin = 1
			this.form.quota <= 0 ? this.isQuota = 0 : this.isQuota = 1
			this.$forceUpdate()
		},
		saveGoods() {
			if (Number(this.form.min_price) > Number(this.form.price)) {
				this.$message.error('最低折扣价不能高于商品预订价');
				return
			}
			if (this.isQuota == 0) {
				this.form.quota = 0
			}
			if (this.isLimitMin == 0) {
				this.form.limit_min = 1
			}
			if (!this.form.goods_name) {
				this.$message.error('请输入商品名称');
				return
			}
			if (!this.form.goods_intro) {
				this.$message.error('请输入商品简介');
				return
			}
			if (!this.form.price) {
				this.$message.error('请输入商品预订价');
				return
			}
			if (!this.form.guide_price) {
				this.$message.error('请输入商品指导价');
				return
			}
			if (!this.form.stock) {
				this.$message.error('请输入库存');
				return
			}
			if (!this.form.goods_pic) {
				this.$message.error('请上传商品图');
				return
			}
			if (this.form.arrived_type == 3) {
				if (!this.form.arrived_date) {
					this.$message.error('请上传到货日期');
					return
				}
			} else {
				this.form.arrived_date = ''
			}
			let url = this.dialogType == 1 ? '/erp/v1/goods/add' : '/erp/v1/goods/modify'
			this.$http.post(`${url}`, this.form).then((res) => {
				const {
					code,
					msg
				} = res.data
				if (code == 200) {
					this.$message.success(msg);
					this.dialogVisible = false
					this.getList()
				} else {
					this.$message.error(msg);
				}
			});
		},
		// 确定
		confirmFn() {
			if (Number(this.form.price) * 0.9 > Number(this.form.min_price)) {
				this.$confirm('最低折扣价已低于预订价的90%, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.saveGoods()
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			} else {
				this.saveGoods()
			}
		},
		// 删除
		removeFn(item) {
			this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post(`/erp/v1/goods/delete`, {
					goods_ids: item.id.toString()
				}).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		// 下架
		xiajiaFn(item) {
			this.$confirm('此操作将下架该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post(`/erp/v1/goods/field`, {
					id: item.id,
					field: 'status',
					value: 2,
				}).then((res) => {
					this.loading = false
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		// 上传
		handleAvatarSuccess(res, file) {
			this.form.goods_pic = res.data.path
			this.$forceUpdate();
		},
		// 输入框修改值
		inputBlur(e, id, val, name) {
			this.loading = true
			this.$http.post(`/erp/v1/goods/field`, {
				id: id,
				field: name,
				value: val,
			}).then((res) => {
				this.loading = false
				const {
					code,
					msg
				} = res.data
				if (code == 200) {
					this.$message.success(msg);
					this.getList()
				} else {
					this.$message.error(msg);
				}
			});
		}
	},
}
</script>

<style>
.avatar-uploader .el-upload {
	border: 1px dashed #FF720E;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #FF720E;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 125PX;
	height: 125PX;
	line-height: 125PX;
	text-align: center;
}

.avatar {
	width: 125PX;
	height: 125PX;
	display: block;
}
</style>